<template>
  <div class="register-container">
    <div class="banner">
      <div class="banner_img">
        <h2>{{ $t("L_RESTPASSWORD.BANNER_0001") }}</h2>
      </div>
    </div>
    <div class="register-form">
      <el-form ref="registerForm" :model="registerForm" :rules="rules">
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="phone" ref="phone" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.phone"
            :placeholder="$t('L_RESTPASSWORD.FORM_0001')"
            @focus="handleFocus('phone')"
            @blur="handleBlur('phone')"
            auto-complete="off"
          ></el-input>
          <div class="form_tip" v-if="isFormTip4">
            {{ $t("L_RESTPASSWORD.TIP_0014") }}
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode" class="btn" label="用户名">
          <el-input
            type="text"
            v-model="registerForm.verificationCode"
            :placeholder="$t('L_RESTPASSWORD.FORM_0004')"
            auto-complete="off"
          >
            <template slot="append">
              <el-button
                v-if="registerForm.showloginCode"
                type="primary"
                @click="getloginPhoneCode"
                >{{ $t("L_RESTPASSWORD.BUTTON_0003") }}</el-button
              >
              <el-button v-else type="primary"
                >{{ registerForm.count }}s</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="registering"
            @click.native.prevent="handleSubmit"
            class="btn"
            >{{ $t("L_RESTPASSWORD.BUTTON_0001") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {cloneDeep} from "lodash";
import { moveToErr } from "@/utils/index";
import { sendMessageCode, checkMessageCode } from "@/api/register";
export default {
  name: "register",
  data() {
    let validatePhone = (rule, value, callback) => {
      let reg = /^((0\d{2,3}\d{7,8})|(1[358479]\d{9}))$/;
      if (value === "") {
        callback(new Error(this.$t("L_RESTPASSWORD.FORM_0001")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_RESTPASSWORD.TIP_0001")));
        } else {
          return callback();
        }
      }
    };
    let validateCode = (rule, value, callback) => {
      let reg = /^\d{6}$/;
      if (value === "") {
        callback(new Error(this.$t("L_RESTPASSWORD.FORM_0004")));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t("L_RESTPASSWORD.TIP_0008")));
        } else {
          return callback();
        }
      }
    };
    return {
      // 表单参数
      registerForm: {
        phone: "",
        showloginCode: true,
        count: 60,
        verificationCode: "", //表单中展示的验证码
        timer: null,
      },
      rules: {
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verificationCode: [
          { required: true, validator: validateCode, trigger: "blur" },
        ],
      },
      registering: false,
      isFormTip4: false,
      isFormTip8: false,
    };
  },
  methods: {
    handleFocus(param) {
      this.$refs["registerForm"].clearValidate([param]);
      if (param === "phone") {
        this.isFormTip4 = true;
      } else if (param === "validate") {
        this.isFormTip8 = true;
      }
    },
    handleBlur(param) {
      if (param === "phone") {
        this.isFormTip4 = false;
      } else if (param === "validate") {
        this.isFormTip8 = false;
      }
    },
    // 登录
    handleSubmit() {
      this.registering = true;
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          let verifyCode = cloneDeep(this.registerForm.verificationCode);
          let phone = cloneDeep(this.registerForm.phone);
          checkMessageCode(phone, verifyCode).then((res) => {
            if (res.code === 200) {
              this.registering = false;
              this.resetForm();
              setTimeout(() => {
                this.$router.push({
                  name: "resetPassword",
                  params: {
                    verifyCode: verifyCode,
                    phone: phone
                  }
                });
              }, 1500);
            } else {
              this.registering = false;
              if (res.msg) {
                let msg = res.msg;
                this.handleMessaage(msg, "error");
              } else {
                this.handleMessaage(
                  this.$t("L_RESTPASSWORD.TIP_0010"),
                  "error"
                );
              }
              this.resetForm();
              this.resetRegisterForm();
              this.registerForm.verificationCode = "";
            }
          });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.registering = false;
          this.resetRegisterForm();
          this.registerForm.verificationCode = "";
        }
      });
    },
    // 获取手机验证码
    getloginPhoneCode() {
      this.$refs.registerForm.validateField("phone", async (valid) => {
        if (!valid) {
          // 获取验证码
          sendMessageCode({ phone: this.registerForm.phone })
            .then((res) => {
              if (res.code !== 200) {
                return this.handleMessaage(
                  this.$t("L_RESTPASSWORD.TIP_0009"),
                  "error"
                );
              } else {
                // 当验证码发送成功，开始60秒倒计时
                this.resetRegisterForm();
                this.registerForm.showloginCode = false;
                this.registerForm.timer = setInterval(() => {
                  this.registerForm.count--;
                  if (this.registerForm.count <= 0) {
                    this.resetRegisterForm();
                  }
                }, 1000);
              }
            })
            .catch(() => {
              return this.handleMessaage(
                this.$t("L_RESTPASSWORD.TIP_0009"),
                "error"
              );
            });
        } else {
          return false;
        }
      });
    },
    resetRegisterForm() {
      if (this.registerForm.timer !== null) {
        clearInterval(this.registerForm.timer);
        this.registerForm.timer = null;
      }
      this.registerForm.showloginCode = true;
      this.registerForm.count = 60;
    },
    resetForm() {
      this.registerForm.phone = ""
      this.registerForm.verificationCode = ""
      this.$refs["registerForm"].clearValidate(["phone"]);
      this.$refs["registerForm"].clearValidate(["verificationCode"]);
    },
    handleMessaage(text, type) {
      this.$message({
        message: text,
        type: type,
        offset: 100,
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.registerForm.timer);
    this.registerForm.timer = null;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/register";
</style>